import {
    datavizBackground,
    defaultBackground,
    grayBackground,
    midnightBackground,
    semanticBackground,
} from 'src/constants/bgColors';

import './box.scss';

export const boxVariants = {
    alignContent: {
        start: 'align-content-start',
        end: 'align-content-end',
        center: 'align-content-center',
        between: 'align-content-between',
        around: 'align-content-around',
    },
    alignSelf: {
        start: 'align-self-start',
        end: 'align-self-end',
        center: 'align-self-center',
        stretch: 'align-self-stretch',
        baseline: 'align-self-baseline',
    },
    flex: {
        grow: 'flex-grow-1',
        shrink: 'flex-shrink-1',
        none: 'flex-grow-0 flex-shrink-0',
    },
    padding: {
        A: 'paddingA',
        0: 'padding0',
        5: 'padding5',
        10: 'padding10',
        15: 'padding15',
        20: 'padding20',
        25: 'padding25',
        30: 'padding30',
        35: 'padding35',
        40: 'padding40',
    },
    paddingX: {
        A: 'paddingXA',
        0: 'paddingX0',
        5: 'paddingX5',
        10: 'paddingX10',
        15: 'paddingX15',
        20: 'paddingX20',
        25: 'paddingX25',
        30: 'paddingX30',
        35: 'paddingX35',
        40: 'paddingX40',
    },
    paddingY: {
        A: 'paddingYA',
        0: 'paddingY0',
        5: 'paddingY5',
        10: 'paddingY10',
        15: 'paddingY15',
        20: 'paddingY20',
        25: 'paddingY25',
        30: 'paddingY30',
        35: 'paddingY35',
        40: 'paddingY40',
    },
    borderRadius: {
        pill: 'border-radius-pill',
        rounded: 'border-radius-rounded',
        circle: 'border-radius-circle',
    },
    position: {
        static: 'position-static',
        relative: 'position-relative',
        absolute: 'position-absolute',
        fixed: 'position-fixed',
        sticky: 'position-sticky',
    },
    overflow: {
        hidden: 'overflow-hidden',
        scroll: 'overflow-scroll',
        auto: 'overflow-auto',
        autoX: 'overflow-auto-x',
        autoY: 'overflow-auto-y',
    },
    background: {
        ...datavizBackground,
        ...defaultBackground,
        ...grayBackground,
        ...midnightBackground,
        ...semanticBackground,
    },
};
