import type {
    GrayColor,
    DatavizColor,
    DefaultColor,
    MidnightColor,
} from 'src/types';
import type { SemanticColor } from 'src/types/color';

export const semanticBackground: Record<SemanticColor, string> = {
    info: 'bg-info',
    danger: 'bg-danger',
    warning: 'bg-warning',
    success: 'bg-success',
    revision: 'bg-revision',
};

export const grayBackground: Record<GrayColor, string> = {
    'gray-50': 'bg-gray-50',
    'gray-100': 'bg-gray-100',
    'gray-150': 'bg-gray-150',
    'gray-200': 'bg-gray-200',
    'gray-250': 'bg-gray-250',
    'gray-300': 'bg-gray-300',
    'gray-350': 'bg-gray-350',
    'gray-400': 'bg-gray-400',
    'gray-450': 'bg-gray-450',
    'gray-500': 'bg-gray-500',
    'gray-550': 'bg-gray-550',
    'gray-600': 'bg-gray-600',
    'gray-650': 'bg-gray-650',
    'gray-700': 'bg-gray-700',
    'gray-750': 'bg-gray-750',
    'gray-800': 'bg-gray-800',
    'gray-850': 'bg-gray-850',
    'gray-900': 'bg-gray-900',
    'gray-950': 'bg-gray-950',
    'gray-1000': 'bg-gray-1000',
};

export const midnightBackground: Record<MidnightColor, string> = {
    'midnight-50': 'bg-midnight-50',
    'midnight-100': 'bg-midnight-100',
    'midnight-150': 'bg-midnight-150',
    'midnight-200': 'bg-midnight-200',
    'midnight-250': 'bg-midnight-250',
    'midnight-300': 'bg-midnight-300',
    'midnight-350': 'bg-midnight-350',
    'midnight-400': 'bg-midnight-400',
    'midnight-450': 'bg-midnight-450',
    'midnight-500': 'bg-midnight-500',
    'midnight-550': 'bg-midnight-550',
    'midnight-600': 'bg-midnight-600',
    'midnight-650': 'bg-midnight-650',
    'midnight-700': 'bg-midnight-700',
    'midnight-750': 'bg-midnight-750',
    'midnight-800': 'bg-midnight-800',
    'midnight-850': 'bg-midnight-850',
    'midnight-900': 'bg-midnight-900',
    'midnight-950': 'bg-midnight-950',
    'midnight-1000': 'bg-midnight-1000',
};

export const datavizBackground: Record<DatavizColor, string> = {
    'dataviz-1': 'bg-dataviz-1',
    'dataviz-2': 'bg-dataviz-2',
    'dataviz-3': 'bg-dataviz-3',
    'dataviz-4': 'bg-dataviz-4',
    'dataviz-5': 'bg-dataviz-5',
    'dataviz-6': 'bg-dataviz-6',
    'dataviz-7': 'bg-dataviz-7',
    'dataviz-8': 'bg-dataviz-8',
    'dataviz-9': 'bg-dataviz-9',
    'dataviz-10': 'bg-dataviz-10',
    'dataviz-11': 'bg-dataviz-11',
    'dataviz-12': 'bg-dataviz-12',
    'dataviz-13': 'bg-dataviz-13',
    'dataviz-14': 'bg-dataviz-14',
    'dataviz-15': 'bg-dataviz-15',
};

export const defaultBackground: Record<DefaultColor, string> = {
    white: 'bg-white',
    black: 'bg-black',
    current: 'bg-current',
    transparent: 'bg-transparent',
};
