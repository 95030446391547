import { initRoutes } from 'src/utils/router';

export const ROUTES = initRoutes('/artist', {
    ARTIST: {
        path: '/:id',
        search: {
            vendorId: Number,
            subaccountId: Number,
            countries: value => value.split(','),
            timeSeries: Number,
            period: String,
            activityBreakdown: value => value === 'true',
        },
    },
});
