import { PAGINATION_SEARCH } from 'src/hooks/useUrlParamsPagination';
import { initRoutes } from 'src/utils/router';
import type { EmailCampaignStatus } from 'src/apollo/definitions/globalTypes';

export const ROUTES = initRoutes('/email-campaigns', {
    ROOT: {
        path: '/',
    },
    CAMPAIGNS: {
        path: '/campaigns',
        search: {
            ...PAGINATION_SEARCH,
            globalParticipantId: String,
            search: String,
            status: value => value as EmailCampaignStatus,
        },
    },
    CAMPAIGN: {
        path: '/campaigns/:campaignId',
    },
    CAMPAIGN_OVERVIEW: {
        path: '/campaigns/:campaignId/overview',
    },
    CAMPAIGN_ANALYTICS: {
        path: '/campaigns/:campaignId/analytics',
    },
    CAMPAIGN_DESIGN: {
        path: '/campaigns/:campaignId/design',
    },
    CAMPAIGN_PREVIEW: {
        path: '/campaigns/:campaignId/preview',
    },
});
