import { initRoutes } from 'src/utils/router';

// NOTE: Changes to router config pages/connections/routes.ts can affect redirect url for Tiktok login. This can be caused due to the fact that url is not in Tiktok api allowList
export const ROUTES = initRoutes('/connections', {
    CONNECTIONS: {
        path: '/',
        search: {
            editStore: String,
            connectMeta: Boolean,
            connectTiktokUserId: String,
            acceptToS: Boolean,
            auth_code: String, // NOTE: query param returned by Tiktok connector
            id: String, // NOTE: query param returned by Fivetran
        },
    },
});
