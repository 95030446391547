import { LoadingIndicator } from '@theorchard/suite-components';
import cn from 'classnames';

import './loadingOverlay.scss';

export const CLASSNAME = 'LoadingOverlay';
export const CLASSNAME_FULLSCREEN = 'LoadingOverlay--fullscreen';
export const CLASSNAME_OVERCAST = 'LoadingOverlay--overcast';

export type IsomorphicElement<E extends React.ElementType = React.ElementType> =
    {
        as?: E;
    } & Omit<React.ComponentProps<E>, keyof E>;

export const LoadingOverlay = <
    E extends React.ElementType = typeof LoadingIndicator,
>({
    as,
    fullscreen,
    overcast,
    testId,
    ...props
}: IsomorphicElement<E> & {
    fullscreen?: boolean;
    overcast?: boolean;
    testId?: string;
}) => {
    const className = cn([
        CLASSNAME,
        {
            [CLASSNAME_FULLSCREEN]: fullscreen === true,
            [CLASSNAME_OVERCAST]: overcast === true,
        },
    ]);

    const Element = as ?? LoadingIndicator;
    return (
        <div className={className} data-testid={testId}>
            <Element {...props} />
        </div>
    );
};
